// This is a file that can be overridden by the application in order to override styles
// Notice that this file is included at the very end of the stylesheets packs to have
// more priority
//
// To override the Tailwind CSS configuration you can do so by following the instructions
// available at https://docs.decidim.org/en/develop/customize/styles.html#_tailwind_css
//
// By default this is empty.

@import "../partials/fonts";
@import "../partials/homepage";
@import "../partials/footer";

:root{
    --primary-color: #0184c0;
    --secondary-color: #140676;
    --tertiary-color: #dc871c;
    --success-color: #4eb051;
    --alert-color: #e52132;
    --warning-color: #fdc100;
    --pantone-7710C: #00a3a6;
    --pantone-1915C: #ea4b80;
    --pantone-2577C: #9275b3;
    --pantone-663C: #f0efea;
}