@font-face {
  font-family: "Wallop";
  src: url("fonts/Wallop-Medium.woff") format("woff"), url("fonts/Wallop-Medium.woff2") format("woff2");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Wallop";
  src: url("fonts/Wallop-Light.otf") format("otf");
  font-style: normal;
  font-weight: 400;
}
body,
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.title-decorator, .home__section-title,
.home__section-participate-grid__title {
  font-family: Wallop, sans-serif;
}

.home__menu {
  background-color: var(--secondary-color);
}

a#hero-cta {
  background-color: var(--tertiary-color);
}

a#hero-cta:hover, #sub_hero a {
  background-color: var(--primary-color);
}

#sub_hero a {
  background-color: var(--success-color);
}

footer .main-footer, .main-footer__top, .main-footer__down, footer .mini-footer {
  background-color: var(--pantone-663C) !important;
}

.main-footer__top div {
  color: var(--primary-color) !important;
}

.main-footer__down nav ul li,
.mini-footer__content,
.main-footer__language-container button,
.main-footer__middle {
  color: var(--secondary-color) !important;
}

#trigger-dropdown-language-chooser {
  background-color: var(--secondary-color) !important;
}

.middle-footer-text {
  inline-size: 600px;
}

@media (max-width: 768px) {
  .middle-footer-text {
    inline-size: 300px;
  }
}
.footer-logos {
  @apply block;
}
.footer-logos a {
  @apply inline-block mr-8;
}
.footer-logos svg {
  @apply w-auto h-6;
}
.footer-logos svg path {
  fill: var(--secondary-color) !important;
}

:root {
  --primary-color: #0184c0;
  --secondary-color: #140676;
  --tertiary-color: #dc871c;
  --success-color: #4eb051;
  --alert-color: #e52132;
  --warning-color: #fdc100;
  --pantone-7710C: #00a3a6;
  --pantone-1915C: #ea4b80;
  --pantone-2577C: #9275b3;
  --pantone-663C: #f0efea;
}